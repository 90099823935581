<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="5" sm="4" xl="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'policy_list_create' }"> 添加 </b-button>
          <div class="wocc">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="p-0">
              <template v-slot:button-content class="p-0" style="padding: 0">
                <b-button variant="primary" class="ml-1" v-if="piliangArray.length > 0"> 批量操作 </b-button>
              </template>
              <b-dropdown-item @click="deleteInvoicePl">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>批量删除</span>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = piliangArray)]">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>批量审核</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>

        <!-- Search -->
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="请输入订单编号、保单编号、或会员ID" />
            <BFormDatepicker v-model="startTime" class="d-inline-block mr-1" placeholder="请选择开始时间" :reset-button="true" />
            <BFormDatepicker v-model="endTime" class="d-inline-block mr-1" placeholder="请选择结束时间" :reset-button="true" />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
      style="min-height: 400px"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&#x2610;</span>
          <span class="sr-only">Not Selected</span>
        </template>
      </template>

      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <div class="font-medium-1">{{ data.item.member.real_name }}</div>
        <div>{{ data.item.create_level }}</div>
        <div class="grid-hero" style="color: #ccc">
          {{ data.item.member.id }}
        </div>
      </template>
      <template #cell(product)="data">
        <div class="font-medium-1">
          {{ data.item.product.type.type_id == 1 ? '人寿' : '年金' }}
        </div>
        <div class="grid-hero" style="color: #ccc">
          {{ data.item.product.title }}
        </div>
      </template>

      <template #cell(number)="data">
        <div class="font-medium-1">{{ data.item.number }}</div>

        <div class="grid-hero" style="color: #ccc" v-if="data.item.product.type.type_id == 1">{{ data.item.product.type.type_id == 1 ? '面额' : '年金' }}: {{ data.item.money }} ￥</div>
        <div class="grid-hero" style="color: #ccc" v-else>年金: {{ data.item.money }} ￥</div>
      </template>

      <template #cell(product.title)="data">
        <div class="font-medium-1">{{ data.item.product.title }}</div>
        <div class="grid-hero" style="color: #ccc">产品: {{ data.item.product.type.type_name }}</div>
      </template>
      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge :variant="data.item.status | filterStatusColor" class="mx-auto">{{ data.item.status | filterStatusTxt }}</b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(share_number)="data">
        <b-link>
          #<span class="text-nowrap" @click="getMoneyInfo(data.item, data.item.id)"> {{ data.item.share_rate.length }}人 </span>
        </b-link>
      </template>
      <template #cell(order_tp)="data">
        <b-link>
          #<span class="text-nowrap" @click="setItemDetail(data)">
            {{ data.item.order_tp }}
          </span>
        </b-link>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal2'), ((checkForm2.id = data.item.id), (checkForm2.order_tp = data.item.order_tp))]">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>添加付款</span>
          </b-dropdown-item>
         <!--  <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal3'), ((checkForm3.id = data.item.id), (checkForm3.order_tp = data.item.order_tp))]">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>添加扣款</span>
          </b-dropdown-item> -->

          <b-dropdown-item @click="setItemEdit(data)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>编辑</span>
          </b-dropdown-item>
          <b-dropdown-item @click="setItemDetail(data)">
            <feather-icon icon="EyeIcon" class="mr-50" />
            <span>详情</span>
          </b-dropdown-item>
          <b-dropdown-item @click="backOrder(data)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>撤回</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteInvoice(data.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>删除</span>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">
            <feather-icon icon="MessageCircleIcon" class="mr-50" />
            <span>审核</span>
          </b-dropdown-item>
        </b-dropdown>
        <!--        <div class="f-flex">-->
        <!--          &lt;!&ndash;      编辑    &ndash;&gt;-->
        <!--          <b-button size="sm" variant="primary" :to="{ name: 'policy_list_edit', params: { id: data.item.id } }" @click="$store.commit('user-order/setOrderDetail', data.item)">-->
        <!--            编辑-->
        <!--            <feather-icon icon="EditIcon" />-->
        <!--          </b-button>-->
        <!--          &lt;!&ndash;      删除    &ndash;&gt;-->
        <!--          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>-->
        <!--            <template v-slot:button-content>-->
        <!--              <b-button size="sm" variant="danger">-->
        <!--                删除-->
        <!--                <feather-icon icon="TrashIcon" />-->
        <!--              </b-button>-->
        <!--            </template>-->
        <!--            <b-dropdown-item>-->
        <!--              <feather-icon icon="TrashIcon" class="mr-50" />-->
        <!--              <span @click="deleteInvoice(data.item.id)">确认删除</span>-->
        <!--            </b-dropdown-item>-->
        <!--          </b-dropdown>-->
        <!--          &lt;!&ndash;          审核&ndash;&gt;-->
        <!--          <b-button size="sm" variant="success" @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">-->
        <!--            审核-->
        <!--            <feather-icon icon="CheckIcon" />-->
        <!--          </b-button>-->
        <!--        </div>-->
      </template>
    </b-table>
    <b-modal id="audit-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="订单审核">
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group label="订单TP">
            <validation-provider #default="{ errors }" name="TP" rules="required">
              <b-form-input id="register-title" v-model="checkForm.all_tp" name="register-title" :state="errors.length > 0 ? false : null" placeholder="请输入TP" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="订单状态:">
            <validation-provider #default="{ errors }" name="审核状态" rules="required">
              <b-form-radio-group v-model="checkForm.status" :state="errors.length > 0 ? false : null">
                <!--                <b-form-radio value="1">已提交</b-form-radio>-->
                <b-form-radio value="2">通过</b-form-radio>
                <b-form-radio value="-1">驳回</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal')">取消</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice">确认</b-button>
      </template>
    </b-modal>
    <b-modal id="audit-modal2" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" title="添加付款">
      <validation-observer ref="auditForm2">
        <b-form>
          <!-- <b-form-group label="付款日期:">
            <validation-provider #default="{ errors }" name="付款日期" rules="required">
              <b-form-input v-model="checkForm2.fk_time" type="email" placeholder="请输入付款日期" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group label="订单TP值:">
  <validation-provider
    #default="{ errors }"
    name="订单TP"
    rules="required"
  >
    <b-form-input
      v-model="checkForm2.order_tp"
      type="number"
      placeholder="请输入订单TP"
      :state="errors.length > 0 ? false : null"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</b-form-group>


          <!-- <b-form-group label="付款时间:">
            <validation-provider #default="{ errors }" name="付款时间" rules="required">
              <flat-pickr v-model="checkForm2.pay_time" :config="timePickerConfig" placeholder="请选择付款时间" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group label="付款时间:">
            <validation-provider #default="{ errors }" name="付款时间">
              <!-- <b-form-input
                v-model="checkForm2.fk_time"
                type="date"
                placeholder="请输入付款日期"
                :state="errors.length > 0 ? false : null"
              /> -->
              <flat-pickr v-model="checkForm2.pay_time" class="form-control" placeholder="请选择付款时间" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="佣金状态:">
            <validation-provider #default="{ errors }" name="佣金状态" rules="required">
              <b-form-radio-group v-model="checkForm2.count_cate" :state="errors.length > 0 ? false : null">
                <b-form-radio value="1">第一年佣金</b-form-radio>
                <b-form-radio value="2">续费佣金</b-form-radio>
                <b-form-radio value="3">超额佣金</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal2')">取消</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice2">确认</b-button>
      </template>
    </b-modal>

    <b-modal id="audit-modal3" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" title="添加扣款">
      <validation-observer ref="auditForm3">
        <b-form>
          <b-form-group label="订单TP值:">
            <validation-provider #default="{ errors }" name="订单TP" rules="required|minNum0">
              <b-form-input
                v-model="checkForm3.order_tp"
                type="number"
                min="0"
                placeholder="请输入订单TP"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="付款时间:">
            <validation-provider #default="{ errors }" name="付款时间">
              <flat-pickr v-model="checkForm3.pay_time" class="form-control" placeholder="请选择付款时间" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="佣金状态:">
            <validation-provider #default="{ errors }" name="佣金状态" rules="required">
              <b-form-radio-group v-model="checkForm3.count_cate" :state="errors.length > 0 ? false : null">
                <b-form-radio value="1">第一年佣金</b-form-radio>
                <b-form-radio value="2">续费佣金</b-form-radio>
                <b-form-radio value="3">超额佣金</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal3')">取消</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice3">确认</b-button>
      </template>
    </b-modal>

    <b-modal id="money-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="分担信息查看" size="lg">
      <b-table :items="fenqianField" responsive :fields="fenqianFieldColumn" primary-key="id" show-empty empty-text="No matching records found" class="position-relative" />
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('money-modal')">关闭</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="确认删除" size="sm">
      请确认是否删除所选保单？
      <template #modal-footer>
        <b-button variant="danger" @click.prevent="confirmPLDelete">确认</b-button>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('delete-modal')">关闭</b-button>
      </template>
    </b-modal>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRadio, BFormRadioGroup, BFormTimepicker, BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { reactive, getCurrentInstance, nextTick, ref } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import zh from 'flatpickr/dist/l10n'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    flatPickr,
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return '已提交'
        case 2:
          return '已审核'
        case 3:
          return '已付款'
        case 4:
          return '已完成'
        default:
          return '未知'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'danger'
      }
    },
  },
  setup() {
    const yulandis = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)
    }

    const {
      startTime,
      endTime,
      auditInvoice: auditInvoiceStore,
      auditInvoice2: auditInvoiceStore2,
      auditInvoice3: auditInvoiceStore3,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,
      deleteInvoice,
      refetchData,
    } = useInvoicesList(proxy)
    const dateQuery = ref('')
    const checkForm = reactive({
      status: '',
      id: '',
      all_tp: '',
    })
    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }
    const checkForm2 = reactive({
      order_tp: '',
      id: '',
      count_cate: '',
      fk_time: '',
      pay_time: null,
    })

    const checkForm3 = reactive({
      order_tp: '',
      id: '',
      count_cate: '',
      fk_time: '',
      pay_time: null,
    })

    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        console.log(isOk)
        if (isOk) {
          if (Array.isArray(checkForm.id)) {
            proxy.$bvToast.toast('功能暂未开放')
          } else {
            auditInvoiceStore(checkForm)
          }
        } else {
          console.log('error')
        }
      })
    }
    const auditInvoice2 = () => {
      proxy.$refs.auditForm2.validate().then(isOk => {
        console.log(isOk)
        if (isOk) {
          if (Array.isArray(checkForm2.id)) {
            proxy.$bvToast.toast('功能暂未开放')
          } else {
            auditInvoiceStore2(checkForm2)
          }
        } else {
          console.log('error')
        }
      })
    }
    const auditInvoice3 = () => {
      proxy.$refs.auditForm3.validate().then(isOk => {
        console.log(isOk)
        if (isOk) {
          if (Array.isArray(checkForm3.id)) {
            proxy.$bvToast.toast('功能暂未开放')
          } else {
            auditInvoiceStore3(checkForm3)
          }
        } else {
          console.log('error')
        }
      })
    }
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      {
        key: 'user_id',
        label: '会员id',
      },
      {
        key: 'real_name',
        label: '会员名称',
      },
      {
        key: 'level',
        label: '会员等级',
      },
      {
        key: 'share_rate',
        label: '分担比例',
        formatter: (value, row) => `${value}%`,
      },
      // {
      //   key: 'tp',
      //   label: '分担TP',
      //   formatter: (value, row) => `${value}`,
      // },
      // {
      //   key: 'money',
      //   label: '分担金额',
      // },
      {
        label: '状态',
        key: 'stu_name',
      },
    ]

    const lock = ref(false)
    const getMoneyInfo = (item, id) => {
      document.body.style.cursor = 'wait'
      if (lock.value) return
      lock.value = true
      setTimeout(() => {
        axiosIns
          .get('/order/getRateInfo', { params: { order_id: id } })
          .then(response => {
            if (response.code == 0) {
              proxy.$bvModal.show('money-modal')
              nextTick(() => {
                fenqianField.splice(0, fenqianField.length, ...response.data)
              })
              lock.value = false
              document.body.style.cursor = 'default'
            } else {
              document.body.style.cursor = 'default'
              lock.value = false
              proxy.$bvToast.toast(response.msg, {
                title: '提示',
                variant: 'danger',
                solid: true,
                appendToast: true,
                autoHideDelay: 2000,
              })
            }
          })
          .catch(() => {
            lock.value = false
            document.body.style.cursor = 'default'
            proxy.$bvToast.toast('网络错误', {
              title: '提示',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
          })
      }, 1000)
    }

    const setItemEdit = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        // query 传
        proxy.$router.push({
          name: 'policy_list_edit',
          query: {
            id: data.item.id,
          },
        })
      }, 500)
    }

    const setItemDetail = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_detail',
          query: {
            id: data.item.id,
          },
        })
      }, 500)
    }

    // 撤回
    const backOrder = data => {
      axiosIns
        .put('order/back', {
          order_id: data.item.id,
        })
        .then(res => {
          if (res.code == 0) {
            proxy.$bvToast.toast('撤回成功', {
              title: '提示',
              variant: 'success',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
            refetchData()
          } else {
            proxy.$bvToast.toast(res.msg, {
              title: '提示',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
          }
        })
    }

    const setItemEditCreate = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_add_money',
        })
      }, 500)
    }

    const selectable = ref(true)

    const piliangForm = reactive({
      id: '',
    })
    const piliangArray = ref([])
    const contentChange = (item, index, event) => {
      if (piliangArray.value.indexOf(item.id) == -1) {
        piliangArray.value.push(item.id)
      } else {
        piliangArray.value.splice(piliangArray.value.indexOf(item.id), 1)
      }
    }

    const deleteInvoicePl = () => {
      if (piliangArray.value.length == 0) {
        proxy.$bvToast.toast('请选择要删除的订单', {
          title: '提示',
          variant: 'danger',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
      } else {
        // 弹出是否删除提示框
        proxy.$bvModal.show('delete-modal')
      }
    }
    const confirmPLDelete = () => {
      axiosIns.delete('/order/delete-all', { params: { ids: piliangArray.value } }).then(response => {
        if (response.code == 0) {
          proxy.$bvModal.hide('delete-modal')
          proxy.$bvToast.toast('删除成功', {
            title: '提示',
            variant: 'success',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
          refetchData()
        } else {
          proxy.$bvModal.hide('delete-modal')
          proxy.$bvToast.toast(response.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        }
      })
    }

    return {
      yulandis,
      timePickerConfig,
      startTime,
      endTime,
      setItemEditCreate,
      confirmPLDelete,
      deleteInvoicePl,
      piliangArray,
      piliangForm,
      contentChange,
      setItemDetail,
      selectable,
      setItemEdit,
      fenqianField,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,
      refetchData,
      deleteInvoice,
      auditInvoice,
      auditInvoice2,
      auditInvoice3,
      checkForm,
      checkForm2,
      checkForm3,
      required,
      getMoneyInfo,
      fenqianFieldColumn,
      dateQuery,
      backOrder,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.form-control-time {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
