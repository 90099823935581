/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList(proxy) {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {
    //   key: 'selected',
    //   label: 'SD',
    //   thStyle: {
    //     width: '30px',
    //     textAlign: 'center'
    //   },
    // },
    {
      key: 'actions',
      label: '操作',
      thStyle: {
        width: '20px',
        padding: '10px 0px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'inherit',
      },
    },
    {
      key: 'id',
      label: '会员名称',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      label: '保险公司',
      key: 'brand_name',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      label: '产品',
      // key: 'product.title',
      key: 'product',
      thStyle: {
        whiteSpace: 'nowrap',
        width: '50px',
      },
    },
    {
      label: '保单号',
      key: 'number',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    // {
    //   label: '公司产品',
    //   key: 'product.title',
    //   thStyle: {
    //     whiteSpace: 'nowrap'
    //   },
    // },
    // { key: 'create_level', label: '等级' },
    // {
    //   key: 'number',
    //   label: '保单',
    // },
    {
      key: 'protect_user',
      label: '受保人',
      formatter: value => (!value ? '-- 无 --' : `${value}`),
      thStyle: {
        whiteSpace: 'nowrap',
        width: '40px',
      },
    },
    {
      label: '分担情况',
      key: 'share_number',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    // {
    //   key: 'product.title',
    //   label: '公司',
    // },

    // {
    //   label: '佣金类型',
    //   key: 'count_cate',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    //   thStyle: {
    //     whiteSpace: 'nowrap',
    //   },
    // },
    {
      label: '总TP',
      key: 'order_tp',
      thStyle: {
        whiteSpace: 'nowrap',
        width: '50px',
      },
    },
    // {
    //   label: '年金保费',
    //   key: 'year_money',
    //   thStyle: {
    //     whiteSpace: 'nowrap'
    //   },
    // },
    {
      label: '派发率(%)',
      key: 'rate',
      thStyle: {
        width: '30px',
        whiteSpace: 'nowrap',
      },
    },
    // {
    //   label: '付费百分比',
    //   key: 'fee_rate',
    //   formatter: value => (!value ? '-- 无 --' : `${value}%`),
    //   thStyle: {
    //     whiteSpace: 'nowrap'
    //   },
    // },

    {
      key: 'all_money',
      label: '总应收佣金',
      thStyle: {
        whiteSpace: 'nowrap',
        width: '70px',
      },
    },
    {
      key: 'created_at',
      label: '提交时间',
    },
    // {
    //   key: 'apply_time',
    //   label: '生效日期',
    // },
    {
      key: 'status',
      label: '状态',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    // {
    //   key: 'desc',
    //   label: '备注',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    //   thStyle: {
    //     whiteSpace: 'nowrap'

    //   },
    // },
  ]
  const perPage = ref(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const perPageOptions = [10, 25, 50, 100] // pageSize Array
  const searchQuery = ref('') // searchQuery

  const startTime = ref('')
  const endTime = ref('')
  const user_id = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value ? orderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, startTime, endTime], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('user-order/fetchListData', {
        perPage: perPage.value,
        page: currentPage.value,
        number: searchQuery.value,
        // user_id: user_id.value,
        startTime: startTime.value,
        endTime: endTime.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // 删除
  const deleteInvoice = val => {
    // 弹窗确认
    proxy.$bvModal.msgBoxConfirm('此操作将永久删除该保单, 是否继续?', {
      title: '提示',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      okTitle: '确定',
      cancelTitle: '取消',
    }).then(res => {
      if (!res) return
      store
        .dispatch('user-order/orderDelete', val)
        .then(response => {
          if (response.code == 0) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.msg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            refetchData()
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.msg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '删除错误!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    })
  }
  // 添加付款
  const auditInvoice2 = val => {
    store
      .dispatch('user-order/orderFk', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal2')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 添加付款
  const auditInvoice3 = val => {
    store
      .dispatch('user-order/withhold', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal3')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 审核
  const auditInvoice = val => {
    store
      .dispatch('user-order/orderAudit', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 订单详情

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    orderListTable,
    deleteInvoice,
    auditInvoice,
    auditInvoice2,
    auditInvoice3,
    refetchData,
    startTime,
    endTime,
  }
}
